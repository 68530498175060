





































































































































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import {
  Account_account,
  EquipmentSituation,
  InspectionApprovalState,
  InspectionStatus,
  PaginateInspections_paginateInspections_data,
  PaginateInspections_paginateInspections_data_address,
  PaginateInspections_paginateInspections_data_address_dealer,
  PaginateInspections_paginateInspections_data_asset,
} from '@/types/intrador';
import { Meta } from '@sophosoft/vue-meta-decorator';
import { Filter, FilterType } from '@/layouts/back-office/elements/filters/FilterMixin';
import { DataTableColumn, DataTableSearchable } from '@/layouts/back-office/elements/datatable/DataTableMixin';
import AccountManagerFilterMixin from '@/filters/dataTable/AccountManagerFilterMixin';
import ApproveInspections from '@/components/inspection/actions/ApproveInspections.vue';
import BackToTopButton from '@/layouts/back-office/elements/BackToTopButton.vue';
import CancelInspections from '@/components/inspection/actions/CancelInspections.vue';
import EquipmentSituationIcon from '@/components/snapshot/EquipmentSituationIcon.vue';
import ExportSnapshots from '@/components/inspection/actions/ExportInspections.vue';
import ExtendInspection from '@/components/inspection/actions/ExtendInspection.vue';
import InspectionAnomaliesLabel from '@/components/inspection/InspectionAnomaliesLabel.vue';
import InspectionInspectorAvatar from '@/components/inspection/InspectionInspectorAvatar.vue';
import InspectionStatusLabel from '@/components/inspection/InspectionStatusLabel.vue';
import MetaList from '@/components/MetaList.vue';
import QueryAutocomplete from '@/layouts/back-office/elements/input/InputAutocomplete.vue';
import QueryDataTable from '@/layouts/back-office/elements/datatable/QueryDataTable.vue';
import SubmitInspections from '@/components/inspection/actions/SubmitInspections.vue';
import TransferSnapshots from '@/components/inspection/actions/TransferInspections.vue';
import TrustedLabel from '@/components/TrustedLabel.vue';
import UpdateInspections from '@/components/inspection/actions/UpdateInspections.vue';
import AnomaliesAmount from '@/components/inspection/anomalies/AnomaliesAmount.vue';
import Score from '@/components/planning/partials/Score.vue';
import SendInspectionsReminderEmailAction from '@/components/dealer/actions/SendInspectionsReminderEmailAction.vue';
import BulkCreateAppraisals from '@/components/appraisal/actions/BulkCreateAppraisals.vue';
import BulkCreateAuctionByInspection from '@/components/inspection/actions/BulkCreateAuctionByInspection.vue';
import {pastDays} from '@/plugins/Date';

interface MetaDict {
  [key: string]: string|null;
}

interface InspectionAssetWithMetaDict extends PaginateInspections_paginateInspections_data_asset {
  metaDict?: MetaDict;
}

interface InspectionAddressDealerWithMetaData extends PaginateInspections_paginateInspections_data_address_dealer {
  metaDict?: MetaDict;
}

interface InspectionAddressWithMetaDict extends PaginateInspections_paginateInspections_data_address {
  dealer: InspectionAddressDealerWithMetaData;
}

interface InspectionWithMetaDict extends PaginateInspections_paginateInspections_data {
  asset: InspectionAssetWithMetaDict;
  address: InspectionAddressWithMetaDict;
}

@Component({
  components: {
    BulkCreateAuctionByInspection,
    BulkCreateAppraisals,
    AnomaliesAmount,
    ApproveInspections,
    BackToTopButton,
    CancelInspections,
    EquipmentSituationIcon,
    ExportSnapshots,
    ExtendInspection,
    InspectionAnomaliesLabel,
    InspectionInspectorAvatar,
    InspectionStatusLabel,
    MetaList,
    QueryAutocomplete,
    QueryDataTable,
    Score,
    SendInspectionsReminderEmailAction,
    SubmitInspections,
    TransferSnapshots,
    TrustedLabel,
    UpdateInspections,
  },
})
export default class Inspections extends Mixins(AccountManagerFilterMixin) {

  private InspectionApprovalState = InspectionApprovalState;
  private InspectionStatus = InspectionStatus;

  private inspectorId: string | null = null;

  @Prop(String) private success?: string;

  private created() {
    this.inspectorId = this.$route.query.inspectorId as string;
  }

  /**
   * The DataTable columns
   */
  private get columns(): DataTableColumn[] {
    return [
      {
        title: '#',
        key: 'id',
        sortable: true,
        class: 'data-table-col-fixed',
        width: 55,
      },
      {
        title: this.$it('inspections.col.asset.title', 'Asset') as string,
        key: 'asset.name',
        sortable: true,
        class: 'data-table-col-min',
        width: 200,
      },
      {
        title: this.$it('inspections.col.location.title', 'Location') as string,
        key: 'address.name',
        sortable: 'address.name',
        class: 'data-table-col-min',
        width: 200,
      },
      {
        title: this.$it('inspections.col.inspector.title', 'Inspector') as string,
        tooltip: {
          message: this.$it('inspections.col.inspector.title', 'Inspector') as string,
        },
        key: 'inspector',
        sortable: 'inspector.name',
        class: 'data-table-col-fixed data-table-col-center',
        width: 80,
      },
      {
        title: this.$it('inspections.col.planning.title', 'Planning') as string,
        key: 'plannedAt',
        sortable: true,
        class: 'data-table-col-fixed data-table-col-center',
        width: 110,
      },
      {
        title: this.$it('inspections.col.equipmentSituation.title', 'Situation') as string,
        key: 'equipmentSituation',
        sortable: true,
        class: 'data-table-col-fixed data-table-col-center',
        width: 100,
        permission: 'inspection-situations-get',
      },
      {
        title: this.$it('inspections.col.anomaly.title', 'Anomalies') as string,
        tooltip: {
          message: this.$it('inspections.col.anomaly.title', 'Anomalies') as string,
        },
        key: 'anomaliesAmount',
        class: 'data-table-col-fixed data-table-col-center',
        width: 60,
      },
      {
        title: this.$it('inspections.col.score.title', 'Score') as string,
        key: 'score',
        class: 'data-table-col-fixed data-table-col-center',
        width: 80,
        permission: 'inspections-score-get',
      },
      {
        title: this.$it('inspections.col.status.title', 'Status') as string,
        key: 'status',
        sortable: true,
        class: 'data-table-col-fixed data-table-col-center',
        width: 120,
      },
    ];
  }

  /**
   * Fields of the entity that are searchable as well
   */
  private get extraSearchable(): DataTableSearchable[] {
    const searchable: DataTableSearchable[] = [];

    // Load extra fields from entity
    const data: { account: Account_account } | null = this.$apollo.getClient().store.getCache().read({
      query: require('@/graphql/Account.gql'),
      optimistic: true,
    });

    // Check if we have data in the cache
    if (data && data.account) {
      // Load fields and adapt to null values
      const fields = data.account.entity?.config?.snapshotSearchableMetaFields || [];

      // For each additional fields
      for (const key of fields) {
        let name = key;
        // Because we need the literal $it statements in order to connect the specific
        // translation keys to the build we use a switch to map fields with their names.
        switch (key) {
          case 'asset.meta.contract_number':
            name = this.$it('global.contract-number', 'Contract Number') as string;
            break;
          case 'asset.meta.vendor_invoice_number':
            name = this.$it('global.vendor-invoice-number', 'Vendor Invoice Number') as string;
            break;
        }

        if (key && name) {
          // Add to fields
          searchable.push({
            key,
            name,
          });
        }
      }
    }

    return searchable;
  }

  /**
   * The DataTable searchable fields
   */
  private get searchable(): DataTableSearchable[] {
    // Default searchable fields
    return [
      {
        key: 'id',
        name: '#',
      },
      {
        key: 'externalId',
        name: this.$it('global.external-id', 'External ID') as string,
      },
      {
        key: 'asset.name',
        name: this.$it('snapshot.col.asset.title', 'Asset') as string,
      },
      {
        key: 'asset.serial_number',
        name: this.$it('global.serial-number', 'Serial Number') as string,
      },
      {
        key: 'asset.license_plate',
        name: this.$it('global.license-plate', 'License Plate') as string,
      },
      {
        key: 'asset.dealer.id',
        name: this.$it('snapshot.col.dealer-id.title', 'Dealer id') as string,
      },
      {
        key: 'asset.dealer.externalId',
      },
      {
        key: 'address.name',
        name: this.$it('snapshot.col.branch.title', 'Branch') as string,
      },
      {
        key: 'inspector.name',
        name: this.$it('snapshot.col.inspector.title', 'Inspector') as string,
      },
      {
        key: 'inspector.email',
        name: this.$it('snapshot.col.inspector-email.title', 'Inspector email') as string,
      },
    ].concat(this.extraSearchable);
  }

  /**
   * The DataTable filters
   */
  private get filters(): Filter[] {
    return [
      this.accountManagerFilter,
      {
        key: 'dealerIds',
        title: this.$it('inspections.filter.dealer.title', 'Dealer') as string,
        type: FilterType.QuerySelect,
        options: {
          query: require('@/graphql/queries/PaginateDealers.gql'),
          dataKey: 'paginateDealers',
        },
      },
      // TODO: When backend accepts this filter, enable it
      // {
      //   key: 'inspectorIds',
      //   title: this.$it('inspections.filter.inspector.title', 'Inspector') as string,
      //   type: FilterType.QuerySelect,
      //   options: {
      //     query: require('@/graphql/PaginateDealerAccounts.gql'),
      //     dataKey: 'paginateDealerAccounts',
      //   },
      // },
      {
        key: 'status',
        title: this.$it('inspections.filter.status.title', 'Status') as string,
        type: FilterType.Select,
        options: this.$inspection.status.getFilterOptions([
          InspectionStatus.CREATED,
          InspectionStatus.PLANNED,
          InspectionStatus.STARTED,
          InspectionStatus.FINISHED,
          InspectionStatus.ON_HOLD,
          InspectionStatus.TRANSFER_PENDING,
          InspectionStatus.EXPIRED,
          InspectionStatus.SUBMITTED,
          InspectionStatus.DECLINED,
          InspectionStatus.ACCEPTED,
          InspectionStatus.CANCELLED,
        ]),
      },
      {
        key: 'trusted',
        title: this.$it('audit.detail.trusted', 'Trusted') as string,
        type: FilterType.Boolean,
      },
      {
        key: 'equipmentSituation',
        title: this.$it('inspections.filter.equipment-situation.title', 'Situation') as string,
        type: FilterType.Select,
        options: this.$snapshot.equipmentSituation.getFilterOptions([
          EquipmentSituation.FOUND,
          EquipmentSituation.SOLD,
          EquipmentSituation.TRANSFERRED,
          EquipmentSituation.IN_DEMONSTRATION,
          EquipmentSituation.IN_TRANSIT,
          EquipmentSituation.RENTAL,
          EquipmentSituation.OTHER_SITUATION,
        ]),
      },
      {
        key: 'anomaly',
        title: this.$it('inspections.filter.anomalies.title', 'Anomalies') as string,
        type: FilterType.Boolean,
        permission: 'inspections-warnings-get',
      },
      {
        key: 'date',
        title: this.$it('inspections.filter.date.title', 'Date') as string,
        type: FilterType.Date,
        options: {
          labelStart: this.$it('global.planned-at', 'Planned at') as string,
          labelEnd: this.$it('global.due-at', 'Due at') as string,
          fixedDates: [
            {
              label: this.$it('global.date.last-24-hours', 'Last 24 hours') as string,
              start: pastDays(1),
              end: new Date(),
            },
            {
              label: this.$it('global.date.last-7-days', 'Last 7 days') as string,
              start: pastDays(7),
              end: new Date(),
            },
            {
              label: this.$it('global.date.last-14-days', 'Last 14 days') as string,
              start: pastDays(14),
              end: new Date(),
            },
            {
              label: this.$it('global.date.last-30-days', 'Last 30 days') as string,
              start: pastDays(30),
              end: new Date(),
            },
            {label: this.$it('global.date.all-time', 'All time') as string},
          ],
        },
      },
    ];
  }

  /**
   * When a success message should be displayed
   */
  private get successMessage() {
    if (this.$route.query.created === 'success') {
      return this.$it('inspections.created.title', 'The inspection is succesfully created.');
    }

    return this.success;
  }

  private refetch() {
    (this.$refs.queryDataTable as QueryDataTable).refresh();
    if (this.$refs.query) {
      (this.$refs.query as any).getApolloQuery().refetch();
    }
  }

  private mounted() {
    if (this.successMessage) {
      this.$nextTick(() => {
        this.refetch();
      });
    }
  }

  /**
   * Transform data so it is suitable to display
   *
   * @param data
   *
   * @private
   */
  private update(data: InspectionWithMetaDict[]) {
    return data.filter((i: InspectionWithMetaDict) => {
      if (i.status === InspectionStatus.CANCELLED) {
        return false;
      }

      if (i.asset && i.asset.meta && !i.asset.metaDict) {
        i.asset.metaDict = i.asset.meta.reduce((a: any, x: any) => ({...a, [x.key]: x.value}), {});
      }

      // if (i.address && i.address.dealer && i.address.dealer.meta && !i.address.dealer.metaDict) {
      // i.address.dealer.metaDict = i.address.dealer.meta.reduce((a: any, x: any) => ({...a, [x.key]: x.value}), {});
      // }

      return true;
    });
  }

  @Meta
  private getMetaInfo() {
    return {
      title: this.$it('inspections.list.title', 'Inspections') as string,
    };
  }
}
