




























import {Component, Mixins, Vue, Watch} from 'vue-property-decorator';
import InputMixin from '@/layouts/back-office/elements/input/InputMixin';
import InputText from '@/layouts/back-office/elements/input/InputText.vue';

export interface GeoLocation {
  latitude: number | null;
  longitude: number | null;
}

@Component({
  components: {InputText},
})
export default class InputGeolocation extends Mixins(InputMixin) {
  protected value: GeoLocation | null = null;

  protected center: any;

  protected latitude: number | null = 0;
  protected longitude: number | null = 0;

  protected mapOptions = {
    mapTypeControl: false,
    streetViewControl: false,
    zoomControl: false,
    fullscreenControl: false,
  };

  get marker() {
    return {
      lat: (this.latitude) ? parseFloat(this.latitude.toString()) : null,
      lng: (this.longitude) ? parseFloat(this.longitude.toString()) : null,
    };
  }

  @Watch('value', {immediate: true})
  private updateLatLong(value: GeoLocation | null) {
    if (value !== null) {
      this.latitude = value.latitude;
      this.longitude = value.longitude;

      this.updateCenter();
    }
  }

  private updateCenter() {
    this.center = {
      lat: this.latitude,
      lng: this.longitude,
    };
  }

  private drag(position: any) {
    this.latitude = position.latLng.lat();
    this.longitude = position.latLng.lng();
  }

  @Watch('latitude')
  @Watch('longitude')
  private coordinatesChanged() {
    this.value = {
      latitude: this.latitude,
      longitude: this.longitude,
    };
  }
}
