























































import {Component, Mixins, Prop} from 'vue-property-decorator';
import ActionMixin from '@/layouts/back-office/elements/actions/ActionMixin';
import ActionModal from '@/layouts/back-office/elements/actions/ActionModal.vue';
import {EquipmentSituation, InspectionApprovalState, InspectionStatus, InspectionTypeEnum} from '@/types/intrador';
import InspectionApprovalReasonInput from '@/components/inspection/input/InspectionApprovalReasonInput.vue';
import InputBoolean from '@/layouts/back-office/elements/input/InputBoolean.vue';
import InputDate from '@/layouts/back-office/elements/input/InputDate.vue';
import InputInspector from '@/components/elements/input/InputInspector.vue';

@Component({
  components: {InputInspector, InputDate, InputBoolean, InspectionApprovalReasonInput, ActionModal},
})
export default class ApproveInspections extends Mixins<ActionMixin<any>>(ActionMixin) {
  @Prop(String) protected state!: InspectionApprovalState;
  @Prop(String) protected auditId?: string;

  protected reasonIds: string[] = [];
  protected remark: string | null = null;
  protected dueAt: Date | null = null;
  protected sendBack = false;
  protected declineParameters = false;
  protected inspector: any = null;
  protected inspectorText: any = null;

  public open() {
    this.reasonIds = [];
    this.remark = null;
    this.sendBack = true;
    this.dueAt = null;

    (this.$refs.actionModal as ActionModal).open();
  }

  protected created() {
    const data = { inspectionType: this.$it('global.inspection', 'Inspection') };

    if (this.state === InspectionApprovalState.ACCEPTED) {
      this.title = this.$it('snapshot.action.accepted.title', data, 'Accept {inspectionType}') as string;
      this.permission = 'inspections-approve';
    } else {
      this.title = this.$it('inspection.action.declined.title', data, 'Decline {inspectionType}') as string;
      this.permission = 'inspections-decline';
    }
  }

  get isDeclineState() {
    return this.state === InspectionApprovalState.DECLINED;
  }

  get variableIcon(): string | undefined {
    return this.isDeclineState ? 'times' : 'check';
  }

  get variables() {
    const inspector = (this.$validateEmail(this.inspector))
      ? {email: this.inspector}
      : {id: this.inspector};

    return {
      ids: this.selected.map((item: any) => item.id),
      reasonIds: this.reasonIds,
      remark: this.remark && this.remark.length > 0 ? this.remark : null,
      dueAt: this.dueAt,
      declineParameters: this.sendBack && this.declineParameters,
      inspector,
    };
  }

  get refetchQueries() {
    if (this.auditId) {
      return [{
        query: require('@/graphql/Audit.gql'),
        variables: {
          id: this.auditId,
        },
      }];
    }
  }

  get canSave() {
    if (this.isDeclineState) {
      // At least a reason (checkbox or remark) and if send back to inspector, new due date
      return (
        (!this.sendBack || this.dueAt !== null) &&
        (this.reasonIds.length > 0 || this.remark)
      );
    }

    return true;
  }

  get availableItems(): any[] {
    return this.state === InspectionApprovalState.ACCEPTED
      ? this.selected.filter((inspection: any) => inspection.status === InspectionStatus.SUBMITTED
        || inspection.status === InspectionStatus.DECLINED || inspection.status === InspectionStatus.ON_HOLD
        || (inspection.equipmentSituation === EquipmentSituation.TRANSFERRED
          && (inspection.status === InspectionStatus.PLANNED || inspection.status === InspectionStatus.EXPIRED)))

      : this.selected.filter((inspection: any) => inspection.status === InspectionStatus.SUBMITTED
        || inspection.status === InspectionStatus.ON_HOLD ||
        (inspection.equipmentSituation === EquipmentSituation.TRANSFERRED &&
          (inspection.status === InspectionStatus.PLANNED || inspection.status === InspectionStatus.EXPIRED)));
  }

  protected onSelect() {
    const modal: ActionModal = this.$refs.actionModal as ActionModal;

    this.reasonIds = [];
    this.remark = null;
    this.dueAt = null;
    this.sendBack = false;
    this.declineParameters = false;

    const inspection = this.selected[0];
    const inspector = inspection.inspector;

    this.inspector = inspector.id;
    this.inspectorText = inspector.email;

    modal.open();
  }

  protected done() {
    this.$emit('done', this.$it('inspection.action.success', 'Inspection updated successfully') as string);
  }
}
