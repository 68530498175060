
































import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';
import {InspectionApprovalState} from '@/types/intrador';

@Component({
  components: {Spinner},
})
export default class InspectionApprovalReasonInput extends Vue {
  @Prop(String) protected state!: InspectionApprovalState;

  // @Model('change')
  protected value: string[] = [];

  @Watch('model', {immediate: true})
  private onModelChange(model: string[] | null) {
    this.value = model || [];
  }

  @Watch('value')
  @Emit('input')
  private onValueChange(value: string[] | null) {
    return this.value;
  }

  get isDeclineState() {
    return this.state === InspectionApprovalState.DECLINED;
  }
}
