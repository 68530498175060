







































import { Component, Mixins, Prop } from 'vue-property-decorator';
import {
  PaginateInspections_paginateInspections_data,
  InspectionTypeEnum,
  TransferAuditVariables,
  TransferInspectionsVariables,
  PaginateInspections_paginateInspections_data_address,
  PaginateInspections_paginateInspections_data_address_dealer,
} from '@/types/intrador';
import ActionMixin from '@/layouts/back-office/elements/actions/ActionMixin';
import ActionModal from '@/layouts/back-office/elements/actions/ActionModal.vue';
import QueryAutocomplete from '@/layouts/back-office/elements/input/InputAutocomplete.vue';
import InputBoolean from '@/layouts/back-office/elements/input/InputBoolean.vue';
import InputInspector from '@/components/elements/input/InputInspector.vue';
import InputAddressQuery from '@/layouts/back-office/elements/input/InputAddressQuery.vue';
import InputAddressSelect, {AddressSelectResponse} from '@/components/address/input/InputAddressSelect.vue';

@Component({
  components: {InputAddressSelect, InputAddressQuery, InputInspector, InputBoolean, QueryAutocomplete, ActionModal },
})
export default class TransferInspections extends Mixins<ActionMixin<any>>(ActionMixin) {
  public title: string = this.$it('snapshot.action.transfer.title', 'Transfer inspections') as string;
  public icon?: string = 'exchange-alt';
  public permission: string = 'inspections-transfer'; /* TODO: Permission */

  @Prop({type: Boolean, default: false}) protected isAudit!: boolean;

  protected dealer: PaginateInspections_paginateInspections_data_address_dealer|null = null;
  protected address: AddressSelectResponse|null = null;
  protected user: any = null;
  protected notify: boolean = false;

  get mutation() {
    if (this.isAudit) {
      return require('@/graphql/mutations/TransferAudit.gql');
    }

    return require('@/graphql/mutations/TransferInspections.gql');
  }

  get variables(): TransferInspectionsVariables | TransferAuditVariables {
    const mail = this.$validateEmail(this.user);

    const variables = {
      inspector: {
        id: !mail ? this.user : null,
        email: mail ? this.user.trim().toLowerCase() : null,
        notify: this.notify,
      },
    };

    if (this.isAudit) {
      return {
        id: this.selected[0].id,
        ...variables,
      };
    }

    return {
      ids: this.selected.map((item: PaginateInspections_paginateInspections_data) => item.id),
      addressId: this.address?.id ?? null,
      ...variables,
    };
  }

  get availableItems(): PaginateInspections_paginateInspections_data[] {
    return this.selected.filter((i: PaginateInspections_paginateInspections_data) => {
      return (i.type && i.type.name !== InspectionTypeEnum.Snapshot && i.acceptedAt === null)
        || i.submittedAt === null;
    });
  }

  public onSelect() {
    (this.$refs.actionModal as ActionModal).open();

    const addresses = Object.values(this.availableItems
      .reduce((
        total: {[key: string]: PaginateInspections_paginateInspections_data_address},
        inspection,
      ) => {
        const addressId = inspection.address?.id;
        if (addressId && !(addressId in total)) {
          total[addressId] = inspection.address!;
        }
        return total;
      }, {}));

    const dealers = Object.values(addresses
      .reduce((
        total: {[key: string]: PaginateInspections_paginateInspections_data_address_dealer},
        address,
      ) => {
        const dealerId = address.dealer?.id;
        if (dealerId && !(dealerId in total)) {
          total[dealerId] = address!.dealer!;
        }
        return total;
      }, {}));

    this.dealer = (dealers.length === 1)
      ? dealers[0]
      : null;

    this.address = (addresses.length === 1)
      ? {id: addresses[0].id, name: addresses[0].name!}
      : null;
  }
}
