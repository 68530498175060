













import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inspection_inspection_inspector } from '@/types/intrador';

@Component({})
export default class InspectionInspectorAvatarLabel extends Vue {
  @Prop(Object) protected inspector!: Inspection_inspection_inspector;
  @Prop({ type: String, default: 'inspections' }) protected redirect!: string;
}
