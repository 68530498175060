
















import { Component, Mixins } from 'vue-property-decorator';
import { Inspection_inspection, InspectionStatus } from '@/types/intrador';
import ActionMixin from '@/layouts/back-office/elements/actions/ActionMixin';
import ConfirmActionModal from '@/layouts/back-office/elements/actions/ConfirmActionModal.vue';

@Component({ components: { ConfirmActionModal } })
export default class SubmitInspections extends Mixins<ActionMixin<any>>(ActionMixin) {
  public title: string = this.$it('inspection.action.submit.title', 'Submit Inspections') as string;
  public icon?: string = 'save';
  public permission: string = 'inspections-submit'; /* TODO: Permission */

  protected get variables() {
    return {
      ids: this.selected.map((i: Inspection_inspection) => i.id),
      submittedAt: new Date(Date.now()),
    };
  }

  protected get availableItems(): Inspection_inspection[] {
    return this.selected.filter((i: Inspection_inspection) =>
      i.finishedAt !== null && (i.status === InspectionStatus.FINISHED || i.status === InspectionStatus.EXPIRED));
  }

  protected onSelect() {
    (this.$refs.actionModal as ConfirmActionModal).open();
  }

}
