










import { Component, Vue } from 'vue-property-decorator';

@Component
export default class BackToTopButton extends Vue {
  private viewIsScrolledDown: boolean = false;

  private scrollTo() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  private click() {
    this.scrollTo();
  }

  private handleScroll() {
    this.viewIsScrolledDown = window.scrollY > 1;
  }

  private created() {
    window.addEventListener('scroll', this.handleScroll);
  }

  private destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
