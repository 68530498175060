var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page page-inspections"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('query-data-table',{ref:"queryDataTable",attrs:{"data-key":"paginateInspections","title":_vm.$it('inspections.list.title', 'Inspections'),"query":require('@/graphql/queries/inspection/PaginateInspections.gql'),"variables":{limit: 25, inspectorId: _vm.inspectorId},"columns":_vm.columns,"filters":_vm.filters,"resolve-data":_vm.update,"searchableFields":_vm.searchable,"can-clear-filters":true},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var register = ref.register;
var selected = ref.selected;
return [_c('send-inspections-reminder-email-action',{attrs:{"register":register,"selected":selected}}),_c('extend-inspection',{attrs:{"register":register,"selected":selected}}),_c('transfer-snapshots',{attrs:{"register":register,"selected":selected}}),_c('export-snapshots',{attrs:{"register":register,"selected":selected}}),_c('submit-inspections',{attrs:{"register":register,"selected":selected}}),_c('approve-inspections',{attrs:{"register":register,"selected":selected,"state":_vm.InspectionApprovalState.ACCEPTED}}),_c('approve-inspections',{attrs:{"register":register,"selected":selected,"state":_vm.InspectionApprovalState.DECLINED}}),_c('bulk-create-appraisals',{attrs:{"register":register,"selected":selected}}),_c('bulk-create-auction-by-inspection',{attrs:{"register":register,"selected":selected}}),_c('cancel-inspections',{attrs:{"register":register,"selected":selected},on:{"done":function($event){return _vm.$refs.queryDataTable.refresh()}}})]}},{key:"action-bar-buttons",fn:function(){return [(_vm.$can('inspections-create'))?_c('router-link',{staticClass:"action-bar-button",attrs:{"to":{ name: 'inspections-create'}}},[_vm._v(" "+_vm._s(_vm.$it('global.inspection', 'Inspection'))+" "),_c('i',{staticClass:"fa fa-plus"})]):_vm._e()]},proxy:true},{key:"data-table-prepend",fn:function(){return [(_vm.successMessage)?_c('div',{staticClass:"alert alert-success"},[_vm._v(" "+_vm._s(_vm.successMessage)+" ")]):_vm._e()]},proxy:true},{key:"id",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('div',{staticClass:"thumbnail"},[(item.thumb)?_c('img',{attrs:{"src":item.thumb,"alt":item.name}}):_vm._e()])]}},{key:"asset.name",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'inspections-detail', params: { id: item.id, cancelled: item.status === _vm.InspectionStatus.CANCELLED }}}},[_vm._v(" "+_vm._s(value)),(item.type)?_c('span',{staticClass:"type"},[_vm._v(" — "+_vm._s(item.type.name))]):_vm._e()]),_c('ul',[(item.trusted)?_c('li',[_c('trusted-label')],1):_vm._e(),_c('li',[_c('span',{attrs:{"aria-label":_vm.$it('global.serial-number', 'Serial Number'),"data-balloon-pos":"right"}},[_vm._v(" "+_vm._s(item.asset.serialNumber)+" ")])]),(item.asset.licensePlate)?_c('li',{staticClass:"font-mono"},[_c('span',{attrs:{"aria-label":_vm.$it('global.license-plate', 'License Plate'),"data-balloon-pos":"right"}},[_vm._v(" "+_vm._s(item.asset.licensePlate)+" ")])]):_vm._e(),(item.asset.metaDict.vendor_invoice_number)?_c('li',[_c('span',{attrs:{"aria-label":_vm.$it('global.vendor-invoice-number', 'Vendor Invoice Number'),"data-balloon-pos":"right"}},[_vm._v(" "+_vm._s(item.asset.metaDict.vendor_invoice_number)+" ")])]):_vm._e(),(item.asset.metaDict.contract_number)?_c('li',[_c('span',{attrs:{"aria-label":_vm.$it('global.contract-number', 'Contract Number'),"data-balloon-pos":"right"}},[_vm._v(" "+_vm._s(item.asset.metaDict.contract_number)+" ")])]):_vm._e(),(item.externalId)?_c('li',[_c('span',{attrs:{"aria-label":_vm.$it('global.external-id', 'External ID'),"data-balloon-pos":"right"}},[_vm._v(" "+_vm._s(item.externalId)+" ")])]):_vm._e()])]}},{key:"address.name",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [(item.address)?[(item.address.dealer)?[_c('router-link',{attrs:{"to":{ name: 'branches-detail', params: { id: item.address.dealer.id, branchId: item.address.id }}}},[_vm._v(" "+_vm._s(value)+" ")]),_c('ul',[(item.address.dealer.externalId)?_c('li',[_c('span',{staticClass:"font-mono",attrs:{"aria-label":_vm.$it('global.dealer-id', 'Dealer Id'),"data-balloon-pos":"right"}},[_vm._v(" "+_vm._s(item.address.dealer.externalId)+" ")])]):_c('li',[_vm._v(" "+_vm._s(item.address.city)+", "+_vm._s(item.address.country)+" ")])])]:[_c('div',{staticClass:"line"},[_vm._v(_vm._s(value))]),_c('div',{staticClass:"line"},[_vm._v(" "+_vm._s(item.address.city)+", "+_vm._s(item.address.country)+" ")])]]:_vm._e()]}},{key:"inspector",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [(value)?_c('inspection-inspector-avatar',{attrs:{"inspector":value}}):_vm._e()]}},{key:"equipmentSituation",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [(value)?_c('equipment-situation-icon',{attrs:{"situation":value}}):_vm._e()]}},{key:"anomaliesAmount",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('anomalies-amount',{attrs:{"status":item.status,"inspection":item,"anomalies-amount":value}})]}},{key:"score",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('div',{staticClass:"score"},[(value)?_c('score',{attrs:{"score":value}}):_vm._e()],1)]}},{key:"plannedAt",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [(item.plannedAt && item.dueAt)?_c('div',{attrs:{"aria-label":((_vm.$id(item.plannedAt, 'time')) + " - " + (_vm.$id(item.dueAt, 'time'))),"data-balloon-pos":"left"}},[_c('div',{staticClass:"line"},[_vm._v(_vm._s(_vm.$id(item.plannedAt, 'date')))]),_c('div',{staticClass:"line"},[_vm._v(_vm._s(_vm.$id(item.dueAt, 'date')))])]):(item.plannedAt)?_c('div',{attrs:{"aria-label":("" + (_vm.$id(item.plannedAt, 'time'))),"data-balloon-pos":"left"}},[_c('div',{staticClass:"line"},[_vm._v(_vm._s(_vm.$id(item.plannedAt, 'date')))])]):_c('div',{attrs:{"aria-label":("" + (_vm.$id(item.createdAt, 'time'))),"data-balloon-pos":"left"}},[_c('div',{staticClass:"line"},[_vm._v(_vm._s(_vm.$id(item.createdAt, 'date')))])])]}},{key:"status",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('inspection-status-label',{attrs:{"status":value}},[(item.hasApprovalRemark)?_c('div',{staticClass:"fas fa-comment-dots"}):_vm._e()])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }