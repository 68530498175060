


















import { Component, Mixins } from 'vue-property-decorator';
import { Inspection_inspection } from '@/types/intrador';
import ActionMixin from '@/layouts/back-office/elements/actions/ActionMixin';
import ActionModal from '@/layouts/back-office/elements/actions/ActionModal.vue';

@Component({
  components: { ActionModal },
})
export default class ExtendInspection extends Mixins<ActionMixin<any>>(ActionMixin) {
  public title: string = this.$it('inspection.action.extend.title', 'Extend Inspections') as string;
  public icon?: string = 'clock';
  public permission: string = 'inspections-extend'; /* TODO: Permission */

  protected dueAt: Date | null = null;

  protected get variables() {
    const dueAt = (this.dueAt !== null) ? this.$toDate12Hour(this.dueAt) : null;

    return {
      ids: this.selected.map((item: Inspection_inspection) => item.id),
      dueAt,
    };
  }

  protected get availableItems(): any[] {
    // You can extend the inspection when it is not submitted, or when it is declined.
    return this.selected.filter((i: Inspection_inspection) => i.submittedAt === null || i.declinedAt !== null);
  }

  protected onSelect() {
    this.dueAt = null;

    (this.$refs.actionModal as ActionModal).open();
  }
}
