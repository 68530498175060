









import {Component, Prop, Vue} from 'vue-property-decorator';
import { ToggleChoice } from './ToggleInterface';

export { ToggleChoice };

@Component({
  components: {},
})
export default class Toggle extends Vue {
  @Prop(Array) private choices!: ToggleChoice[];

  private choice: any = null;

  protected get availableChoices(): ToggleChoice[] {
    return this.choices.filter((choice) => typeof choice.permission === 'undefined' || this.$can(choice.permission));
  }

  private mounted() {
    this.choice = this.choices[0].value;
    this.$emit('input', this.choice);
  }

  private set(choice: any) {
    this.choice = choice;
    this.$emit('input', choice);
  }
}
