































import {Component, Mixins, Prop, Vue} from 'vue-property-decorator';
import ActionModelMixin from '@/layouts/back-office/elements/actions/ActionModelMixin';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';
import { saveAs } from 'file-saver';
import SpinnerButton from '@/layouts/back-office/elements/SpinnerButton.vue';
import slugify from '@/filters/vue/slugify';

@Component({
  components: {SpinnerButton, Spinner, Modal},
})
export default class DownloadActionModal extends Mixins(ActionModelMixin) {
  @Prop(String) protected url!: string;
  @Prop(String) protected filename!: string;
  @Prop({type: Boolean, default: true}) protected directDownload!: boolean;

  private downloading: boolean =  false;

  get canDownload() {
    return !this.downloading && this.canSave;
  }

  public open() {
    const modal = this.$refs.modal as Modal;

    modal.open();

    if (this.directDownload) {
      this.download();
    }
  }

  private async download() {
    if (this.canSave && !this.downloading) {
      this.downloading = true;
      this.error = null;

      const response = await this.$http.get(this.url, {
        responseType: 'blob',
        params: this.variables,
      }).catch((error) => {
        if (error.response && error.response.data) {
          this.error = error.response.data.message;
        } else {
          this.error = this.$it('global.error.occurred', 'An error has occurred') as string;
        }
      });

      this.downloading = false;
      if (response) {
        const lastIndex = this.filename.lastIndexOf('.');
        saveAs(response.data, slugify(this.filename.substr(0, lastIndex)) + this.filename.substr(lastIndex));
        this.done(null);
      }
    }
  }
}
