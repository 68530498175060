



















































import { Component, Mixins, Prop } from 'vue-property-decorator';
import QueryAutocomplete from '@/layouts/back-office/elements/input/InputAutocomplete.vue';
import ComboSelect from '@/layouts/back-office/elements/input/InputComboSelect.vue';
import InputMixin from '@/layouts/back-office/elements/input/InputMixin';
import md5 from 'md5';

@Component({
  components: { ComboSelect, QueryAutocomplete },
})
export default class Inspector extends Mixins(InputMixin) {
  @Prop({type: Boolean, default: false}) protected clearable!: boolean;
  @Prop(String) private text!: string;
  @Prop(String) private placeholder?: string;
  @Prop({type: Boolean, default: false}) private onlyUseOptions!: boolean;
  @Prop({type: Array, default: () => []}) private options!: any[];
  @Prop({type: Boolean, default: false}) private fixed!: boolean;
  @Prop({default: 'left'}) private balloonPosition!: string;
  @Prop({type: Boolean, default: true}) private floatOverInput!: boolean;
  @Prop({type: Boolean, default: true}) private allowArbitraryEmails!: boolean;
  @Prop({type: Object, default: () => require('@/graphql/PaginateDealerAccounts.gql')}) private query!: object;
  @Prop({type: String, default: 'paginateDealerAccounts'}) private dataKey!: boolean;

  private isValidEmail?: boolean = false;

  private getName(item: any) {
    this.$emit('itemSelected', item);

    return (this.$validateEmail(item))
      ? item.trim().toLowerCase()
      : (item.name)
        ? item.name
        : item.email;
  }

  private selectEmail(selectCallback: ((search: string) => void) | null, search: string, disabled: boolean) {
    if (!disabled) {
      this.$emit('itemSelected', search);

      if (selectCallback !== null) {
        selectCallback(search);
      }
    }
  }

  private avatar(email: string) {
    return `https://www.gravatar.com/avatar/${md5(email)}?s=64`;
  }
}
