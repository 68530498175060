



























































import {Component, Emit, Mixins, Model, Prop, Vue, Watch} from 'vue-property-decorator';
import QueryAutocomplete from '@/layouts/back-office/elements/input/InputAutocomplete.vue';
import InputText from '@/layouts/back-office/elements/input/InputText.vue';
import InputTextarea from '@/layouts/back-office/elements/input/InputTextarea.vue';
import InputMixin from '@/layouts/back-office/elements/input/InputMixin';
import InputGeolocation, {GeoLocation} from '@/layouts/back-office/elements/input/InputGeolocation.vue';

@Component({
  components: {InputGeolocation, InputTextarea, InputText, QueryAutocomplete},
})
export default class InputAddressQuery extends Mixins(InputMixin) {
  @Prop({type: Boolean, default: true}) protected searchable!: boolean;
  @Prop({type: Boolean, default: false}) protected changeGeolocation!: boolean;
  @Prop({type: Boolean, default: false}) protected alwaysEditable!: boolean;

  @Prop({type: Object, default: () => require('@/graphql/Addresses.gql')}) protected query!: boolean;
  @Prop({type: String, default: 'searchAddresses'}) protected dataKey!: boolean;

  private loading = false;
  private modelUpdateBlock = false;

  private id: number | null = null;
  private placeId: string | null = null;

  private name: any = null;
  private addressLine1: any = null;
  private zipCode: any = null;
  private city: any = null;
  private country: any = null;
  private remark: any = null;

  private geolocation: GeoLocation = {
    latitude: null,
    longitude: null,
  };

  get isClearable() {
    return this.clearable && this.isFilled && !this.inputDisabled;
  }

  get inputDisabled() {
    return this.loading || ((this.id !== null || this.placeId !== null) && !this.alwaysEditable);
  }

  get remarkDisabled() {
    return this.loading || (this.id !== null && !this.alwaysEditable);
  }

  private onItemSelect(value: any) {
    if (value) {
      if (value.__typename === 'GooglePlaces') {
        this.setGooglePlaces(value);
      } else {
        this.setAddress(value);
      }
    }
  }

  @Watch('model', {immediate: true})
  private modelUpdated(address: any) {
    if (address && !this.modelUpdateBlock) {
      this.setAddress(address, false);
    }
  }

  private setAddress(address: any, changed: boolean = true) {
    this.clearAddress(false);

    this.modelUpdateBlock = true;

    this.id = address.id;
    this.placeId = address.placeId;

    this.name = address.name;
    this.addressLine1 = address.addressLine1;
    this.zipCode = address.zipCode;
    this.city = address.city;
    this.country = address.country;

    this.geolocation.latitude = (address.latitude) ? address.latitude : null;
    this.geolocation.longitude = (address.longitude) ? address.longitude : null;

    this.remark = (address.remark) ? address.remark : null;

    this.$nextTick(() => {
      this.modelUpdateBlock = false;
    });

    if (changed) {
      this.onItemChange();
    }
  }

  private async setGooglePlaces(address: any) {
    this.clearAddress(false);

    this.loading = true;
    this.modelUpdateBlock = true;

    const addressByPlaceId = await this.$apollo.query({
      query: require('@/graphql/AddressByPlaceId.gql'),
      variables: {
        placeId: address.id,
      },
    });

    const data = addressByPlaceId.data.addressByPlaceId;

    this.id = null;
    this.placeId = data.id;
    this.name = data.name;
    this.addressLine1 = data.addressLine1;
    this.zipCode = data.zipCode;
    this.city = data.city;
    this.country = data.country;

    this.loading = false;

    this.$nextTick(() => {
      this.modelUpdateBlock = false;
    });

    this.onItemChange();
  }

  private clearAddress(changed: boolean = true) {
    this.id = null;
    this.placeId = null;
    this.name = null;
    this.addressLine1 = null;
    this.zipCode = null;
    this.city = null;
    this.country = null;
    this.remark = null;

    if (changed) {
      this.onItemChange();
    }
  }

  @Emit('change')
  private onItemChange(field: string | null = null) {
    return {
      id: this.id,
      name: this.name,
      addressLine1: this.addressLine1,
      zipCode: this.zipCode,
      city: this.city,
      country: this.country,
      latitude: this.geolocation.latitude,
      longitude: this.geolocation.longitude,
      remark: this.remark,
      placeId: this.placeId,
    };
  }

}
