



















import { Component, Mixins, Prop } from 'vue-property-decorator';
import { PaginateInspections_paginateInspections_data } from '@/types/intrador';
import ActionMixin from '@/layouts/back-office/elements/actions/ActionMixin';
import DownloadActionModal from '@/layouts/back-office/elements/actions/DownloadActionModal.vue';
import Toggle from '@/components/elements/button/Toggle.vue';
import {ExportToggleChoice} from '@/components/elements/button/ToggleInterface';


@Component({
  components: {Toggle, DownloadActionModal},
})
export default class ExportInspections extends Mixins<ActionMixin<any>>(ActionMixin) {
  public title: string = this.$it('inspection.action.export.title', 'Export Inspections') as string;
  public icon?: string = 'upload';
  public permission: string = 'inspections-export'; /* TODO: Permission */

  @Prop({type: Object, default: null}) protected audit!: any | null;
  @Prop({type: Boolean, default: true}) protected directDownload!: boolean;
  @Prop({type: String, default: 'export.xlsx'}) protected filename!: boolean;

  private documentType: string = 'excel';
  protected get documentTypeChoices(): ExportToggleChoice[] {
    const documentTypeChoices: ExportToggleChoice[] = [
      {
        label: this.$it('snapshot.export.excel.title', 'Excel export') as string,
        value: 'excel',
        icon: 'far fa-file-excel',
        url: '/inspections/exports/inspections',
      },
    ];

    if (this.$store.getters.user.entity.id === '883') {
      documentTypeChoices.push({
        label: this.$it('inspection.pdf.softpar.title', 'Softpar export') as string,
        value: 'softpar',
        icon: 'far fa-file-excel',
        url: '/inspection/inspections/_export',
      });
    }

    return documentTypeChoices;
  }

  protected get selectedDocumentType(): ExportToggleChoice|undefined {
    return this.documentTypeChoices.find((dtc) => dtc.value === this.documentType);
  }

  protected get iids() {
    return (this.audit && this.audit.import)
      ? this.audit.import.inspections.map((item: any) => item.id)
      : this.selected.map((item: PaginateInspections_paginateInspections_data) => item.id);
  }

  protected get variables() {
    return {
      iids: this.iids,
      ids: this.iids,
      documentType: this.documentType,
    };
  }

  protected onSelect() {
    (this.$refs.actionModal as DownloadActionModal).open();
  }
}
