













import { Component, Prop, Vue } from 'vue-property-decorator';
import { InspectionStatus } from '@/types/intrador';

@Component
export default class InspectionAnomaliesLabel extends Vue {
  @Prop(String) private status!: InspectionStatus;
  @Prop(Number) private anomalies!: number;
  @Prop(Number) private score?: number;
}
