










































import {Component, Mixins} from 'vue-property-decorator';
import ActionMixin from '@/layouts/back-office/elements/actions/ActionMixin';
import ActionModal from '@/layouts/back-office/elements/actions/ActionModal.vue';
import {Inspection_inspection, InspectionStatus} from '@/types/intrador';

@Component({
  components: {
    ActionModal,
  },
})
export default class SendReminderEmailAction extends Mixins<ActionMixin<any>>(ActionMixin) {
  public title: string = this.$it('email.reminder.action.title', 'Send Reminder') as string;
  public icon?: string = 'envelope-open-text';
  public permission?: string = 'dealers-accounts-send-inspection-reminder';

  private InspectionStatus = InspectionStatus;

  private get variables() {
    return {
      ids: this.selected.map((selection: Inspection_inspection) => selection.id),
    };
  }

  public open() {
    const modal = this.$refs.actionModal as ActionModal;
    modal.open();
  }

  public close() {
    const modal = this.$refs.actionModal as ActionModal;
    modal.close();
  }

  protected onSelect() {
    this.open();
  }

  protected done() {
    this.$emit('done');
  }

  get availableItems(): any[] {
    return this.selected.filter((inspection: Inspection_inspection) => {
      const now = new Date();

      const plannedAtIsAfterNow = (inspection.plannedAt && new Date(inspection.plannedAt) < now);
      const dueAtIsBeforeNow = (inspection.dueAt && new Date(inspection.dueAt) > now);
      const isNotSubmittedOrDeclined = (inspection.submittedAt === null || inspection.declinedAt !== null);

      return plannedAtIsAfterNow && dueAtIsBeforeNow && isNotSubmittedOrDeclined;
    });
  }
}
