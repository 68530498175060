













































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import ActionMixin from '@/layouts/back-office/elements/actions/ActionMixin';
import ActionModal from '@/layouts/back-office/elements/actions/ActionModal.vue';
import {InspectionStatus, InspectionTypeEnum} from '@/types/intrador';

@Component({
  components: {ActionModal},
})
export default class UpdateInspections extends Mixins(ActionMixin) {
  @Prop({type: String, default: InspectionTypeEnum.FinalInspection}) protected inspectionType!: InspectionTypeEnum;

  protected date: DateRange | null = null;
  protected plannedAt: Date | null = null;
  protected dueAt: Date | null = null;

  protected created() {
    if (this.inspectionType === InspectionTypeEnum.Snapshot) {
      this.title = this.$it('snapshot.action.update.title', 'Change planning') as string;
      this.permission = 'inspections-edit';
      this.icon = 'clock';
    } else {
      this.title = this.$it('inspection.action.update.title', 'Update Inspections') as string;
      this.icon = 'edit';
    }
  }

  @Watch('date')
  protected dateChange(date: DateRange | null) {
    this.plannedAt = (date) ? date.start : null;
    this.dueAt = (date) ? date.end : null;
  }

  get variables() {
    return {
      ids: this.selected.map((item: any) => item.id),
      plannedAt: this.plannedAt,
      dueAt: this.dueAt,
    };
  }

  get availableItems(): any[] {
    return this.selected.filter((inspection: any) => inspection.submittedAt === null);
  }

  protected onSelect() {
    const modal: ActionModal = this.$refs.actionModal as ActionModal;

    this.date = null;

    modal.open();
  }
}
