
















import { Component, Mixins } from 'vue-property-decorator';
import { InspectionStatus, PaginateInspections_paginateInspections_data } from '@/types/intrador';
import ActionMixin from '@/layouts/back-office/elements/actions/ActionMixin';
import ConfirmActionModal from '@/layouts/back-office/elements/actions/ConfirmActionModal.vue';

@Component({
  components: { ConfirmActionModal },
})
export default class CancelInspections extends Mixins<ActionMixin<any>>(ActionMixin) {
  public title: string = this.$it('inspection.action.cancel.title', 'Cancel Inspections') as string;
  public icon?: string = 'trash-alt';
  public permission: string = 'inspections-cancel'; /* TODO: Permission */

  get variables() {
    return {
      ids: this.selected.map((item: PaginateInspections_paginateInspections_data) => item.id),
    };
  }

  get availableItems(): any[] {
    return this.selected.filter((i: PaginateInspections_paginateInspections_data) =>
      i.status === InspectionStatus.CREATED || i.status === InspectionStatus.PLANNED);
  }

  protected onSelect() {
    (this.$refs.actionModal as ConfirmActionModal).open();
  }
}
