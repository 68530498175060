var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('combo-select',{attrs:{"label":_vm.label || _vm.$it('global.inspector', 'Inspector'),"text":_vm.text,"searchPlaceholder":_vm.placeholder,"valueKey":_vm.getName,"indexKey":"id","only-use-options":_vm.onlyUseOptions,"options":_vm.options,"fixed":_vm.fixed,"query":_vm.query,"dataKey":_vm.dataKey,"error":_vm.error,"clearable":_vm.clearable,"float-over-input":_vm.floatOverInput,"required":_vm.required,"allow-free-text":_vm.isValidEmail,"tab-index":_vm.tabIndex},on:{"validateSearchEmail":_vm.$validateEmail},scopedSlots:_vm._u([(_vm.allowArbitraryEmails)?{key:"beforeSuggestions",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
var search = ref.search;
return [_c('div',{staticClass:"item",class:{selected: isSelected(search)},attrs:{"disabled":!_vm.$validateEmail(search),"aria-label":_vm.$it('global.email.error.not-an-email', 'You must enter an email'),"data-container":"item use-email","data-balloon-pos":_vm.balloonPosition},on:{"click":function($event){_vm.selectEmail(select, search, !_vm.$validateEmail(search))}}},[_c('div',{staticClass:"row account"},[_c('div',{staticClass:"col account-info"},[_c('label',[_vm._v(_vm._s(_vm.$it('global.use', 'Use'))+" "+_vm._s(_vm.$it('global.email', 'Email')))]),_vm._v(" "+_vm._s(search)+" ")])])])]}}:null,{key:"suggestion",fn:function(ref){
var item = ref.item;
var selected = ref.selected;
return [_c('div',{staticClass:"item",class:{selected: selected}},[_c('div',{staticClass:"row no-gutters account"},[_c('div',{staticClass:"col-auto account-avatar"},[_c('img',{attrs:{"src":_vm.avatar(item.email)}})]),_c('div',{staticClass:"col account-info"},[_c('label',[_vm._v(_vm._s(item.name))]),_c('span',[_vm._v(_vm._s(item.email))])])])])]}}],null,true),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})}
var staticRenderFns = []

export { render, staticRenderFns }