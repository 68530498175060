

























import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import InputComboSelect from '@/layouts/back-office/elements/input/InputComboSelect.vue';
import InputMixin from '@/layouts/back-office/elements/input/InputMixin';
import InputText from '@/layouts/back-office/elements/input/InputText.vue';

export interface AddressSelectResponse {
  id: string;
  name: string;
}

@Component({
  components: {InputText, InputComboSelect},
})
export default class InputAddressSelect extends Mixins(InputMixin) {
  protected value: AddressSelectResponse|null = null;

  @Prop({type: Object, default: () => require('@/graphql/queries/branches/PaginateBranches.gql')})
  protected query!: object;
  @Prop({type: String}) protected dealerId?: string;

  protected address: AddressSelectResponse|null = null;

  get variables() {
    if (this.dealerId) {
      return {dealerId: this.dealerId};
    }
    return {};
  }

  @Watch('value', {immediate: true})
  protected valueUpdated() {
    if (this.value) {
      if (this.address === null || (this.address.id !== this.value.id)) {
        this.address = {id: this.value.id, name: this.value.name};
      }
    } else {
      this.address = null;
    }
  }

  @Watch('address')
  protected addressUpdated() {
    if (this.address) {
      this.value = {id: this.address.id, name: this.address.name};
    } else {
      this.value = null;
    }
  }

  protected get readableValue(): string | null {
    return this.address?.name ?? null;
  }
}
