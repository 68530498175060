


















import {Component, Emit, Mixins, Model, Prop, Vue, Watch} from 'vue-property-decorator';
import InputMixin from '@/layouts/back-office/elements/input/InputMixin';

@Component({})
export default class InputBoolean extends Mixins(InputMixin) {
  protected value: boolean|null = null;

  /**
   * Toggles the value to the selected state, if resettable to null when the state has not changed
   *
   * @param toState boolean
   */
  private toggle(toState: boolean) {
    this.value = this.clearable && this.value === toState ? null : toState;
  }
}
